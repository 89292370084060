<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import { getSnippet } from "@/components/Tools/FormHelper/Helper/functions";

export default {
  mixins: [base],
  computed: {
    // Return final snippet for help text
    helpText: function () {
      if (!this.field.help) {
        return "";
      }

      return this.$t(`${this.options.snippetPrefix}.${this.field.help}`);
    }
  },
  methods: {
    getSnippet(text) {
      return getSnippet(text, this.options.snippetPrefix);
    }
  }
};
</script>

<template>
  <div class="h4">
    {{ getSnippet(field.label) }}

    <i
      v-if="field.help"
      v-b-popover.hover.top="helpText"
      class="fal fa-circle-info text-hover-primary ml-1"
    />
  </div>
</template>

<style scoped lang="scss"></style>
